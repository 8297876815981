








































































import {Component} from 'vue-property-decorator';
import {
  dispatchDeleteBiomarker,
  dispatchExpireDataByModuleName,
  dispatchGetBiomarkersForList,
  dispatchGetCategoriesForList,
} from '@/store/crud/actions';
import ConfirmationModal from '@/components/modals/ConfirmationModal.vue';
import AppComponent from '@/mixins/ComponentMixin.vue';
import {readOneCategory} from '@/store/crud/getters';
import {crudModules} from '@/constants/globalConstants';

@Component({components: {ConfirmationModal}})
export default class AdminBiomarkers extends AppComponent {
  public headers = [
    {
      text: 'Name',
      sortable: true,
      value: 'name',
      align: 'left',
    },
    {
      text: 'Category',
      sortable: true,
      value: 'categories',
      align: 'left',
    },
    {
      text: 'quest biomarker code',
      sortable: true,
      value: 'questBiomarkerCode',
      align: 'left',
    },
    {
      text: 'Actions',
      value: 'id',
      align: 'center',
    },
  ];

  public search = '';
  public fetchingBiomarkersData = false;

  /**
   * created hook calls async fetch data
   */
  public created() {
    this.fetchData();
  }

  public getCategoryName(item) {
    const categories: string[] = [];

    if (item && item.categories && item.categories.length > 0) {
      item.categories.forEach((category) => {
        if (category && category.categoryName && category.categoryName !== '') {
          categories.push(category.categoryName || '');
        }
      });
    }

    return categories.join(', ');
  }

  public async fetchData() {
    this.fetchingBiomarkersData = true;
    await this.$nextTick();
    await dispatchGetBiomarkersForList(this.$store);
    await dispatchGetCategoriesForList(this.$store);
    this.fetchingBiomarkersData = false;
  }

  /**
   * Delete a biomarker and refresh the screen
   */
  public async deleteBiomarker(biomarkerId: string) {
    await dispatchDeleteBiomarker(this.$store, {biomarkerId});
    // when a biomarker is deleted, categories should be updated to remove the biomarker from the category
    // this line is to expire the cache for categories
    dispatchExpireDataByModuleName(this.$store, crudModules.CATEGORIES);

    await this.fetchData();
  }
}
